// 开发环境域名

const host_development = 'http://dpt.shoptest.jhfbpt.com'

export default {
// 版本
    version: '1.6.2',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW',
}
